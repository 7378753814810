/* FEATURES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.features-section {
  margin-top: 8vh;
  position: relative;
}
.features-section h1 {
  color: #5E3DFC;
  margin-bottom: 7vh;
}
.features-section h4 {
  -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.feature-item:hover h4 {
  color: #F74164;
}
.cajas05 {
    position: absolute;
    top: -3%;
    right: -15%;
    z-index: 100;
}
.cajas05 img {
  width: 10em;
}
.feature-item img {
  -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.feature-item .fi-img {
  background-color: #5E3DFC;
}
.feature-item:hover img {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .cajas05 {
  top: -3%;
    	right: -6%;
  }
  .cajas05 img {
  width: 9em;
  }
}
@media (min-width: 992px) {
  .features-section {
  margin-top: 20vh;
  }
  .cajas05 {
  top: -9%;
    	right: -6%;
  }
  .cajas05 img {
  width: 11em;
  }
}
@media (min-width: 1200px) {
  .cajas05 {
  top: -12%;
    	right: -5%;
  }
  .cajas05 img {
  width: 13em;
  }
}
