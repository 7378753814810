/* Base for label styling */
.custom-checkbox:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-checkbox:checked {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
.custom-checkbox:not(:checked) + label:before,
.custom-checkbox:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
.custom-checkbox:not(:checked) + label:after,
.custom-checkbox:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
}
/* checked mark aspect changes */
.custom-checkbox:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.custom-checkbox:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.custom-checkbox:disabled:not(:checked) + label:before,
.custom-checkbox:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.custom-checkbox:disabled:checked + label:after {
  color: #999;
}
.custom-checkbox:disabled + label {
  color: #aaa;
}
/* accessibility */
.custom-checkbox:checked:focus + label:before,
.custom-checkbox:not(:checked):focus + label:before {
  border: 2px dotted blue;
}

/* hover style just for information */
label:hover:before {
  border: 2px solid #19083A!important;
}
