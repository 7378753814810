.red-box {
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  border: 0px solid #F74164;
  background-color: #F74164;
  color: white;
}
header .logo-wrapperB {
  background-color: #5E3DFC;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.cancel-action {
  display: flex;
  flex-direction: column;
  padding: 1em !important;
  position: relative;
}

.back-red {
  background-color: #F74164;
  margin-right: 2em;
}

.back-grey {
  background-color: #CCC;
  margin-right: 2em;
}

.btn-box {
  border: 2px solid #19083A;
  -webkit-box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  -moz-box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  padding: 2em 2em 2em 2em;
}
.btn-box:hover {
  -webkit-box-shadow: 1rem 1rem 0px 0.2em rgba(25,8,58,1);
  -moz-box-shadow: 1rem 1rem 0px 0.2em rgba(25,8,58,1);
  box-shadow: 1rem 1rem 0px 0.2em rgba(25,8,58,1);
}

.btn-box-1 {
  -webkit-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  border-radius: 1em;
  padding: 1em;
}

@media screen and (max-width: 768px) {
  .checkout-container .btn-box {
    padding: 1rem 0 1rem 1rem 
  }
}

.padding-2 {
  padding: 1em;
}

.pickers {
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  border-radius: 1em;
  min-height: 15vh;
  font-family: 'Oswald', sans-serif;
  margin-right: 1em;
}

.picker-jump-00 {
  margin-right: 5px;
}

.picker-icon {
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 2em;
}

.picker-text {
  flex: auto;
  justify-content: center;
}

.picker-button {
  flex: auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.edit-button {
  display: flex;
  align-items: center;
  background-color: #5E3DFC;
  align-self: flex-end;
  justify-self: flex-end;
  padding: 3vh 2vw;
  border-bottom-right-radius: 1em;
  border-top-left-radius: 1em;
}

.confirm-order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 50%;
}

.confirm-order-btn-text {
  color: #19083A;
  font-family: 'Oswald', sans-serif;
  font-size: 1.5em;
}

.dashboard-menu-suscription-item {
  display: flex;
  background-color: #19083A;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4vh 2vw 4vh 3vw;
  margin-bottom: 0.5vh;
  font-family: 'Oswald', sans-serif;
  cursor: default;
}
.dashboard-menu-suscription-big-item {
  font-size: 3.5em;
  align-self: center;
}

.dashboard-menu-suscription-first-item {
  color: #C0FFDF;
  font-size: 1.5em;
}
.dashboard-menu-suscription-last-item {
  color: white;
  font-size: 1.5em;
  align-self:flex-end
}

.user-welcome-container {
  display: flex;
  flex: 1 1 75%;
  flex-direction: row;
  font-family: 'Lato', sans-serif;
}
.user-dashboard-container {
  display: flex;
  flex: 1 1 80%;
  flex-direction: column;
  margin-top: 2em;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-right: 5vw;
  justify-content: flex-start;
  align-items: flex-start;
}

.receipt-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 100%;
  font-family: 'Oswald', sans-serif;
}

.welcome-intro {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
}

.welcome-intro-title {
  font-size: 2em;
  font-family: 'Oswald', sans-serif;
  color: #F74164;
  font-weight: bold;
}
.welcome-intro-user {
  font-size: 3em;
  font-weight: 300;
}
.welcome-intro-email {
  color: #F74164;
  font-weight: 300;
  font-size: 1.5em;
}

.welcome-intro-email {
  padding-bottom: 1em;
}

.welcome-message-number {
  width: 1em;
  line-height: 1em;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #F74164;
  display: inline-block;
  color: #F74164;
  margin-right: 1vw;
  border-radius: 50%;
  font-weight: 600;
}


.receipt-box {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.receipt-box hr {
  width: -webkit-fill-available;
  border-color: #19083A;
  flex-grow: 0;
  opacity: 0.5;
  border-width: 0.5px;
}

.receipt-delivery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.receipt-delivery span {
  font-size: 1.5em;
}

.receipt-address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.receipt-address div {
  margin: 1vh 0;
}
.receipt-address div:first-child {
  margin-top: 4vh;
}
.receipt-address img {
  padding-right: 1vw;
}

.receipt-action {
  font-size: 1.5em;
  margin: 1vh 0;
  align-self: center;
}

.receipt-items {
  display: flex;
  flex-direction: column;
}

.receipt-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.receipt-item p{
  flex: 1 0 30%;
  margin: 1vh 0;
}

.receipt-item div {
  flex: 1 0 30%;
  margin: 1vh 0;
}

.receipt-item .total {
  text-align: right;
}

.receipt-item span {
  font-weight: 300;
}

.receipt-additional {
  align-items: flex-start;
}

.receipt-item-value {
  display: flex;
  justify-content: space-between;
  margin-left: 2em;
}

.user-dashboard-banner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5vh;
}

.img-box {
  border-bottom-right-radius: 2em;
  border-top-left-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  margin-right: 1em;
  min-width: 3em;
}

.img-box-span {
  font-family: 'Oswald', sans-serif;
  color: #C0FFDF;
}
.cancel-box-span {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  color: white;
}

.img-box-purple {
  background-color: #5E3DFC;
}

.img-box-blue {
  background-color: #19083A;
}

.dashboard-action {
  display: flex;
  align-items: center;
  position: relative
}


.dashboard-action-active {
  position: absolute;
  width: 3em;
  top: -1.5em;
  right: -1.5em;
}

.dashboard-action-active-left {
  position: absolute;
  width: 3em;
  top: -1.5em;
  left: -1.5em;
}

.dashboard-action-instructions {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}

.dashboard-action-title {
  margin-right: 2em;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}

.user-dashboard-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5vh;
}


.user-dashboard-content-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.2);
  border-radius: 2em;
  flex: 0 1 22%;
  margin-bottom: 1em;
  margin-right: 1em;
  min-height: 141px;
  font-size: 1rem;
}

.user-dashboard-content-add-box {
  border: 3px dashed #E2DADC;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  border-radius: 2em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 1 22%;
  margin-bottom: 1em;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
}

.user-dashboard-content-add-box-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #E2DADC;
}

.user-dashboard-content-box-title {
  display: flex;
  flex-direction: row;
  padding: 1vh 1vw;
  font-family: 'Oswald', sans-serif;
}

.storable-selection {
  padding-left: 2em;
}

.user-dashboard-content-box-placeholder {
  width: 5em;
}

.user-dashboard-content-box-footer {
  display: flex;
  flex-direction: row;
  background-color: #E2DADC;
  border-bottom-right-radius: 2em;
  border-bottom-left-radius: 2em;
  justify-content: flex-end;
  align-items: center;
}

.user-dashboard-content-box-additional {
  display: flex;
  flex-direction: column;
  margin-right: 1vw;
}

.user-dashboard-content-box-inventory {
  display: flex;
  flex-direction: row;
  background-color: #19083A;
  color: #C0FFDF;
  padding: 0.5em;
  border-bottom-right-radius: 2em;
  border-bottom-left-radius: 2em;
  align-items: center;
  width: -webkit-fill-available;
  cursor: pointer;
  justify-content: flex-end;
}

.user-dashboard-content-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1380px) {
  .user-dashboard-content-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .user-dashboard-content-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 420px) {
  .user-dashboard-content-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.notSelectable {
  opacity: 0.2;
  user-select: none;
  pointer-events: none;
}

.go-back {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #19083A;
  max-width: 16em;
  height: 10em;
}
.go-back-placeholder {
  width: 16em;
}
.dashboard-menu-item-arrow-back {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.receipts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.receipts-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 2em;
  flex: 1 1 100%;
}

@media only screen and (min-width: 768px) {
}
/* MEDIA QUERIES */
@media only screen and (max-width: 767px) {
  header {
    padding: 0;
  }
  .flex-row {
    margin-bottom: 2vh;
  }
  .receipt-action {
    font-size: 1.5em;
  }
  .welcome-container {
    margin-right: 0;
    flex: 1 1 60%;
  }
  .user-welcome-container {
    padding: 2vh 6vw 2vh 2vw;
    margin-top: 0;
    flex-direction: column;
  }
  .welcome-intro-user {
    font-size: 4em;
  }
  .welcome-intro {
    margin-bottom: 0;
  }
  .user-dashboard-container {
    margin-top: 0em;
  }
  .user-dashboard-banner {
    flex-direction: column;
  }
  .btn-box {
    padding: 1em 2em 1em 1em;
  }
  .img-box-span {
    text-align: center;
  }
  .img-box {
    padding: 1em;
    min-width: 7em;
  }
  .padding-2 {
    padding: 1em;
  }
  .back-red {
    margin-right: 5vw;
  }
  .flex-column-mobile {
    flex-direction: column !important;
  }
  .user-dashboard-container {
    width: -webkit-fill-available;
    padding: 0.3em;
  }
  .user-dashboard-content {
    flex-direction: column;
    margin-left: 1em;
    align-items: inherit;
  }
  .user-dashboard-content-box {
    flex: 1 1 100%;
  }
  .user-dashboard-content-add-box {
    flex: 1 1 100%;
    margin-right: 1em;
  }
  .user-dashboard-content-box-additional {
    margin-right: 20vw;
  }
  .user-dashboard-content-box-title {
    padding: 2vh 2vw;
  }
  .user-dashboard-content-box-placeholder {
    margin-right: 4vw;
  }
  .go-back, .go-back-placeholder {
    display: none;
  }
  .dashboard-action-instructions {
    flex-direction: column;
    align-items: flex-start;
  }
  .receipts-container {
    flex-direction: column;
    justify-content: inherit;
  }
  .user-dashboard-banner {
    margin-bottom: 1em;
  }
  .sidebar-button {
    background-color: #19083A;
    color: #C0FFDF;
    height: 3em;
    border-radius: 0em;
    width: 5em;
  }
  .pickers {
    margin-right: 0
  }
  .picker-jump-00 {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar-button {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .order-action-button {
    margin: 1rem 1rem 5rem 1rem ;
  }
}

.order-action-button {
  background: #F74164;
  color: white;
}

.order-action-button img{
  filter: invert(1) grayscale(1);
}