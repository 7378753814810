.toolTip {
  display: inline-block;
  position: relative;
}

.toolTip-icon {
  border: 1px solid rgba(25,8,58,1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  width: 15px;
  height: 15px;
  top: -16px;
  position: relative;
  box-shadow: 3px 3px 0 rgba(25,8,58,1);
}

.toolTip:hover .toolTip-message {
  display: block;
}
.toolTip-message:hover {
  box-shadow: 13px 13px 0 rgba(25,8,58,1);
}

.toolTip-message {
  display: none;
  border: 1px solid rgba(25,8,58,1);
  box-shadow: 10px 10px 0 rgba(25,8,58,1);
  position: absolute;
  width: 200px;
  right: -200px;
  top: -1px;
  font-size: 13px;
  padding: .7rem;
  margin: 0 !important;
  background-color: white;
}