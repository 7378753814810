.scrollable-content {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  flex: 1 1 auto;
}
.dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.margin-mobile {
  margin-left: 5em;
  margin-right: 5em;
}
.blue-item {
  color: #5E3DFC !important;
}
.dark-blue-item {
  color: #19083A !important;
}
.red-item {
  color: #F74164 !important;
}
.grey-item {
  color: #C4B4B8 !important;
}
.green-item {
  color: #C0FFDF !important;
}
.white-item {
  color: #FFF !important;
}
.big-item {
  font-size: 2em !important;
  padding-right: 1vw;
}
.medium-item {
  font-size: 2.5em !important;
}
.small-item {
  font-weight: 300;
}
.margin-top-1 {
  margin: 1em 0;
}
.no-margin {
  margin: 0 !important;
}
.main-title {
  font-size: 1.5em;
  color: #19083A;
  font-weight: bolder;
  font-family: 'Oswald', sans-serif;
  align-self: center;
}
.main-subtitle {
  font-size: 1.5em;
  color: #C4B4B8;
  font-family: 'Oswald', sans-serif;
}
.pointer {
  cursor: pointer;
}
.btn-primary {
  background-color: #5E3DFC;
  color: #FFF;
}
.btn-back {
  background-color: #F74164;
  color: #FFF;
}
.hide-node {
  display: none;
}
/* MENU */
.dashboard-menu {
  flex: 1 1 20%;
  margin-right: 2vw;
  display: flex;
  flex-direction: column;
}
.dashboard-menu-item {
  display: flex;
  background-color: #19083A;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 2vw 2vh 3vw;
  margin-bottom: 0.5vh;
  text-decoration: none;
}
.unique-dashboard-menu-item {
  display: flex;
  background-color: #19083A;
  flex-direction: row;
  align-items: center;
  padding: 2vh 2vw;
  margin-bottom: 1vh;
  text-decoration: none;
  min-height: 5em;
}
.dashboard-menu .dashboard-menu-item:first-child {
  background-color: #5E3DFC;
  filter: brightness(85%);
  cursor: default;
}
.dashboard-menu .dashboard-menu-item:first-child p {
  font-family: 'Lato', sans-serif;
  font-weight: 350;
  font-size: 1.5em;
  word-spacing: 100vw;
  margin: 0;
}
.dashboard-menu .dashboard-menu-item:first-child small {
  font-family: 'Oswald', sans-serif;
}
.dashboard-menu .dashboard-menu-item:last-child {
  border-bottom-right-radius: 2em;
}
.dashboard-menu-item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-menu-item-orders {
  border-top: 1px solid #19083A;
  border-bottom: 1px solid #19083A;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.7em;
}
.dashboard-menu-item p {
  font-family: 'Oswald', sans-serif;
  color: #C0FFDF;
  text-transform: uppercase;
  font-weight: 350;
  margin-bottom: 0;
}
.dashboard-menu-item-icon {
  margin-right: 1vw;
}
.dashboard-menu-item-arrow {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.dashboard-menu-item-arrow img {
  height: 8px;
}
/* CONTENT */
.dashboard-content {
  display: flex;
  flex: 1 1 75%;
  flex-direction: column;
  font-family: 'Oswald', sans-serif;
}
.dashboard-content-header {
  display: flex;
  flex-direction: column;
  flex: 1 1 10%;
  justify-content: flex-start;
}
.dashboard-content-header-item {
  display: flex;
  flex-direction: column;
  margin-right: 2vw;
  flex: 1 1 25%;
}
.dashboard-content-header-item:last-child {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  flex: 1 1 75%;
}
.dashboard-content-header-title {
  font-weight: 350;
  flex-grow: 1;
}
.dashboard-content-header-item hr {
  width: -webkit-fill-available;
  border-color: black;
  flex-grow: 0;
}
.dashboard-content-header-body {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
}
.dashboard-content-header-body-item {
  display: flex;
  flex-direction: row;
  margin-right: 2vw;
  font-weight: 600;
}
.dashboard-content-header-body-item-number {
  color: black;
  font-size: 2em;
  vertical-align: text-top;
  display: table-cell;
  margin-right: 0.5em;
}
.dashboard-content-header-body-item-number-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.dashboard-content-header-body-item-number-title img {
  height: 4vh;
}
.dashboard-content-header-body-item-number-title span {
  color: #F74164;
}
.dashboard-content-body {
  display: flex;
  flex: 1 1 90%;
  flex-direction: column;
}
.dashboard-content-body h2 {
  font-size: 2em;
}
.dashboard-content-body li {
  font-size: 1.5em;
}
/* DASHBORD ORDERS */
.order-day-header {
  display: flex;
  flex-direction: row;
  flex: 1 1 10%;
  justify-content: space-between;
  align-items: flex-end;
}
.order-day-header-item {
  font-size: 1.5em;
  flex: 1 1 13%;
  margin-right: 2vw;
  font-weight: 450;
}
.order-day-content {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.order-day-content-item {
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  box-shadow: 0 0 10px #E3D8DB;
  margin-right: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  min-height: 10em;
}
.order-day-content-item-header {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: space-between;
  font-size: 1.5em;
}
.date-tag {
  padding: 1vh 0 1vh 1vw;
}
.today-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7800F4;
  border-radius: 0px 10px 0px 10px;
  padding: 1vh;
}
.order-day-content-item-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-end;
  padding-bottom: 1vh;
  padding-left: 1vw;
}
.order-day-content-item-body-orders {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order-day-content-item-footer {
  border-radius: 0px 0px 14px 14px;
  background-color: #E3D8DB;
  text-align: center;
  height: 7vh;
  position: relative;
}
.order-day-content-item-progress {
  background-color: #7800F4;
  height: 7vh;
  border-radius: 0px 0px 14px 14px;
  top: 0;
  position: absolute;
}
/* Current Order of the day */
.current-order-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 80%;
}
.current-order-item {
  display: flex;
  flex-direction: row;
  padding: 2vh 2vw;
  cursor: pointer;
  justify-content: space-between;
  border-radius: 1em;
  box-shadow: 0 0 1em #E3D8DB;
  margin-bottom: 2em;
}
.current-order-item-time {
  font-size: 2em;
  padding-right: 2vw;
}
.current-order-item-status {
  font-size: 2em;
  padding-right: 2vw;
}
.current-order-item-address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
/* Order Show */
.order-client {
  display: flex;
  flex-direction: column;

}
.order-client span {
  font-size: 1.5em;
}
.order-aditionals {
  display: flex;
  flex-direction: column;
}
.order-aditionals div {
  font-size: 1.5em;
}

.order-client-card {
  border: 2px solid #19083A;
  box-shadow: 0.8em 0.8em 0px 0.2em rgb(25 8 58);
  padding: 2rem;
}

/* Order Recolect */
.order-recolect {
  display: flex;
  flex-direction: column;
}
.recolect-item {
  display: flex;
  flex-direction: column;
}
.recolect-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  position: relative;
}
.recolect-item-photos {
  display: flex;
  flex-direction: row;
  min-height: 160px;
  border-radius: 1em;
  box-shadow: 0 0 10px #E3D8DB;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 1em;
}
.recolect-item-photo {
  border-radius: 2em;
  padding: 1em;
}
.recolect-item-text {
  min-height: 5em;
  margin: 0.5em;
  border-radius: 0.5em;
  font-size: 2em;
  padding: 0.5em;
}
.mobile-row {
  display: flex;
  flex-direction: row;
}
.map {
  position: relative;
  width: 360px;
  height: 300px
}
.camera-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid rgb(204, 204, 204);
  background: black;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
.camera-modal-title {
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.camera-modal-close {
  z-index: 999;
}

.select-upload-photo-method {
  display: flex;
}

.upload-photo-method {
  margin: 0 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 97px;
  box-shadow: 0 0 10px #e3d8db;
  font-size: 50px;
  cursor: pointer;
  background-color: #F74164;
  color: white;
  box-shadow: 0.8rem 0.8rem 0px 0.2rem rgb(25 8 58);
  border: 2px solid #19083A;
  position: relative;
}
.upload-photo-method:first-child {
  margin-left: 0;
}

.upload-photo-method:hover {
  box-shadow: 1rem 1rem 0px 0.2rem rgb(25 8 58);
}

.photo-btn .btn-label,
.file-btn .btn-label {
  position: absolute;
  display: none;
  font-size: .9rem;
  color: #fff;
  font-weight: 900;
}

.photo-btn:hover .btn-label,
.file-btn:hover .btn-label {
  display: block;
  width: 100%;
  height: 100%;

  background-color: rgba(247, 65, 100, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-area {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.recolect-card {
  border: 2px solid #19083A;
  box-shadow: 0.8em 0.8em 0px 0.2em rgb(25 8 58);
  margin-bottom: 2rem;
  overflow: hidden;
}

.recolect-item-qr-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  border-radius: 0 0 0 10px;
  color: white;
  cursor: pointer;
}

.order-aditionals-columns {
  display: flex;
}

@media only screen and (min-width: 768px) {
  /* tablets and desktop */
  .dashboard-container {
    padding: 4em 2em 2em 0;
  }
  .order-day-content-item {
    flex: 1 0 12%;
  }
  .react-html5-camera-photo {
    flex: 1 1 50%;
    align-self: center;
  }
  .camera-modal-close {
    display: flex;
    color: white;
    justify-content: center;
    top: -2em;
    right: -2em;
    position: absolute;
  }
  .camera-modal-close img {
    width: 10vw;
  }
}

.new-in-subscription {
  position: absolute;
  top: -8px;
  padding: 0.1rem 0.25rem;
  right: -3rem;
  font-size: 10px;
  background-color: red;
  color: white;
  border-radius: 4px;
}

.relative {
  position: relative;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 767px) {
  .mobile-row {
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
  }
  .dashboard-container {
    padding-top: 4em;
  }
  .margin-mobile {
    margin-left: 0;
    margin-right: 0;
  }
  .current-order-item {
    flex-direction: column;
  }
  .dashboard-content-header-item {
    margin-right: 0;
    padding-left: 1em;
  }
  .dashboard-content-header-body {
    flex-direction: column;
  }
  .order-day-content {
    flex-direction: column;
    align-items: inherit;
  }
  .order-day-content-item-body {
    flex-direction: row;
    justify-content: space-around;
  }
  .order-day-header {
    display: none;
  }
  .dashboard-menu {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    background-color: #19083A; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 3em; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  }
  .dashboard-menu-opened {
    width: 70vw;
  }
  .camera-modal {
    justify-content: center;
  }
  .camera-modal-close {
    display: flex;
    color: white;
    justify-content: center;
    top: -20px;
    right: -20px;
    position: absolute;
  }
  .camera-modal-close img {
    width: 25vw;
  }
}
@media only screen and (max-width: 992px) {

  .dashboard-content-header  {
    padding: 1rem;
  }
}

.receipts-table tr{
  border-bottom: 1px solid black;
}

.receipts-table td,
.receipts-table th {
  padding: .5rem;
}

.card {
  padding: 1rem;
  box-shadow: 0.8rem 0.8rem 0px 0.2rem rgb(25 8 58);
  border: 2px solid #19083A;
}

.box-info-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.box-info-label {
  color: #7800F4;
  font-size: 1rem;
  margin-bottom: 0;
}

.object-in-list {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.order-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
}

/* @media (min-width: 576px) { ... } */

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) { ... } */

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) { 
  .order-grid {
    grid-template-columns: 1fr;
    margin: 1rem;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { ... } */



.addressCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #19083A;
  padding: 1rem;
  border-radius: 1vh;
  margin-bottom: 1rem;
}
.addressInfoArea {
  display: flex;
  flex-direction: column;
}
.selectAddress {
  display: flex;
}

.newAddress {
  display: flex;
  justify-content: center;
  font-size: larger;
  padding: 1rem;
  border-radius: 1vh;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 2px solid #19083A;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.pill {
  border-radius: 12px;
  padding: .1rem 0.5rem;
  background-color: #7a00f41c;
  font-size: small;
}

.text-small {
  font-size: 12px;
}

.storable-type {
  background-color: #fff;
  transform: translate(-2rem, -2rem);
}

.object-size input{
  width: 65px;
}
