html {
  /* overflow-x: hidden; */
  font-size: 100%;
}
body {
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
  font-size: 100%;
}

.showMobile {
  display: none;
}
@media (min-width: 576px) {
  /**/
}
@media (min-width: 768px) {
  /**/
}
@media (min-width: 992px) {
  /**/
}
@media (min-width: 1200px) {
  /**/
}
h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 2em;
  color: #19083A;
  text-transform: uppercase;
}
h4 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 700;
}
h4 small {
  font-family: 'Lato', sans-serif;
  /*font-weight: 300;*/
  font-style: italic;
  display: block;
  color: #F74164;
  text-transform: none;
  font-size: 0.8rem;
}
h1 small {
  text-transform: none;
  font-size: 0.5em;
  color: #5E3DFC;
  display: block;
}
p {
  font-size: 0.8em;
}
/* NAVBAR ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.navbar {
  -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(94,61,252, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,0);
    border-color: rgba(0,0,0,0);
}
.navbar {
  background-color: #FFF;
}
.navbar-brand img {
  height: 34px;
  width: auto;
}
.navbar-light .navbar-nav .nav-link {
    color: #19083A;
    font-size: 0.8em;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #F74164;
}
.navbar-nav .nav-link {
  padding-left: 1em;
  padding-right: 1em;
}
.navbar-expand-sm .navbar-social .nav-item .nav-link {
  padding-right: 1em;
    padding-left: 1em;
    display: inline-block;
}
.navbar-social {
  list-style-type: none;
  padding: 0;
}
.navbar-social .nav-item {
  display: inline-block;
}
.navbar-social .nav-item .nav-link {
  padding: 2em !important;
}
.navbar-light .nav-item-quote .nav-link {
  color: #5E3DFC;
  background-color: #FFF;
  border-radius: 0.4em;
  -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.navbar-light .nav-item-login {
  margin-left: 0.5em;
}
.navbar-light .nav-item-login .nav-link {
  color: #FFF;
  background-color: #5E3DFC;
  border-radius: 0.4em;
  -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 767px) {
  main {
    padding-top: 3em;
  }
  .navbar {
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white;
  }
  .modal.show .modal-dialog {
    top: 26%;
  }
}
@media (min-width: 576px) {
  .nav-link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 1em;
      padding-left: 1em;
  }
  .navbar-social .nav-item .nav-link {
    padding: 0.5em !important;
  }
}
@media (min-width: 768px) {
  .navbar {
    background-color: rgba(255,255,255,0);
  }
  .bg-white .nav-item-quote .nav-link,
  .bg-white .nav-item-login .nav-link {
    border: 2px solid #5E3DFC;
  }
}
/* MAIN ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.main-slider {
  overflow: hidden;
}
.main-slider .slider-item {
  width: 100%;
  height: 25em;
}
.main-slider .slider01 {
  background-image: url(https://endline1.nyc3.cdn.digitaloceanspaces.com/boxmi/slider_01.jpg);
  background-repeat: no-repeat;
  background-position: 70% 0;
  background-size: cover;
}
.main-slider .slider02 {
  background-image: url(https://endline1.nyc3.cdn.digitaloceanspaces.com/boxmi/slider_02.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.main-slider .slider03 {
  background-image: url(https://endline1.nyc3.cdn.digitaloceanspaces.com/boxmi/slider-03.jpg);
  background-repeat: no-repeat;
  background-position: 70% 0;
  background-size: cover;
}
main .slick-dots {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
main .slick-dots li {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid #19083A;
  position: relative;
  border-radius: 2em;
}
main .slick-dots .slick-active::after {
  content: '';
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  background-color: #5E3DFC;
  border-radius: 2em;
}
main .slick-dots li:not(:last-child) {
  margin-right: 0.8em;
}
main .slick-dots button {
  color: rgba(0,0,0,0);
  min-width: 0;
  min-height: 0;
  border: 0;
  padding: 0;
  height: 12px;
  border-radius: 1em;
}
@media (min-width: 768px) {
  .main-slider .slider-item {
    height: 32em;
  }
  .main-slider .slider01 {
    background-position: top center;
  }
  .main-slider .slider02 {
    background-position: center center;
  }
  .main-slider .slider03 {
    background-position: center center;
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: initial;
    z-index: 900;
  }
}
@media (min-width: 992px) {
  .main-slider .slider-item {
    height: 38em;
  }
}
@media (max-width: 992px) {
  .hideMobile {
    display: none;
  }
  .showMobile {
    display: block;
  }
}
/* COMO FUNCIONA +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.como-funciona ol li {
  /*display: inline-block;*/
}
.como-funciona ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}
.como-funciona ol li {
  counter-increment: my-awesome-counter;
  text-align: center;
  margin-bottom: 5em;
}
.como-funciona ol li::before {
  content: counter(my-awesome-counter) "";
  color: red;
  font-weight: bold;
  display: block;
  text-align: center;
  margin-bottom: 1em;
}
.como-funciona ol li img {
  height: 5em;
}
.como-funciona ol li p {
  width: 60%;
  margin-left: 20%;
  margin-top: 1em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .como-funciona ol li {
    display: inline-block;
    width: 24%;
    vertical-align: text-top;
  }
  .como-funciona ol li img {
    width: 7em;
    height: auto;
  }
  .como-funciona ol li p {
    width: 80%;
    margin-left: 10%;
    font-size: 0.7em;
  }
}
/* SIN COMPLICACIONES ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.sin-complicaciones {
  background: url(../img/sin-complicaciones-background.jpg) no-repeat;
  background-position: top left;
  background-size: auto 50%;
  position: relative;
}
.sin-complicaciones>.container>.row {
  padding-top: 15em;
}
.sc-img-container img {
  width: 10em;
  padding-top: 5em;
  padding-bottom: 3em;
}
.sin-complicaciones p {
  text-transform: uppercase;
  margin: 0;
}
.sin-complicaciones .sc-title-content img {
  height: 1em;
  margin-right: 0.2em;
  display: inline;
}
.sin-complicaciones .sc-title-content p {
  display: inline;
  font-size: 0.6em;
  font-weight: 400;
}
.cajas01 {
  position: absolute;
  top: -60%;
    right: -100%;
  z-index: 100;
}
.cajas01 img {
  width: 30em;
}
@media (min-width: 768px) {
  .sin-complicaciones>.container>.row {
    padding-top: 0em;
  }
  .sin-complicaciones {
    background-position: top left;
    background-size: auto 100%;
  }
  .sc-img-container img {
    width: 9em;
  }
  .cajas01 {
    top: -130%;
      right: -35%;
  }
}
@media (min-width: 992px) {
  .cajas01 {
      right: -40%;
      top: -150%;
  }
  .cajas01 img {
    width: 35em;
  }
}
@media (min-width: 1200px) {
  .cajas01 {
      right: -30%;
  }
}
/* COTIZA ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.cotiza {
  background-image: url(../img/cotiza-background-01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.cotiza h1,
.cotiza p {
  color: #FFF;
}
.cotiza input {
  width: 100%;
  display: block;
  padding: 1em;
  border: 3px solid #19083A;
  font-size: 0.8em;
  position: relative;
  z-index: 1;
}
.cotiza button {
  position: relative;
  z-index: 1;
  border: 3px solid #19083A;
  font-family: 'Oswald', sans-serif;
  font-size: 0.8em;
  text-transform: uppercase;
  background-color: #C0FFDF;
}
.cotiza .form-group {
  position: relative;
}
.cotiza .input-background {
  position: absolute;
  background-color: #19083A;
  display: block;
  top: 0.5em;
  left: 0.5em;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.cajas02 {
  position: absolute;
  top: -10%;
    right: 5%;
  z-index: 100;
}
.cajas02 img {
  width: 6em;
}
.cajas03 {
  position: absolute;
  bottom: -30%;
    left: -15%;
  z-index: 100;
}
.cajas03 img {
  width: 6em;
}
.cajas04 {
  position: absolute;
  top: -10%;
    right: 0%;
  z-index: 100;
}
.cajas04 img {
  width: 6em;
}
.cotiza-footer {
  padding-top: 5rem !important;
}
.cotiza-access-btn,
.cotiza-access-btn:active,
.cotiza-access-btn:link,
.cotiza-access-btn:visited,
.cotiza-access-btn:focus {
  position: absolute;
  width: 15rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background-color: #C0FFDF;
  color: #19083A;
  top: 0;
  left: 50%;
  font-family: 'Oswald', sans-serif;
  font-size: 0.9rem;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.cotiza-access-btn:hover {
  background-color: #D83E5C;
  text-decoration: none;
}
@media (min-width: 768px) {
  .cajas02 {
    top: -20%;
      right: 5%;
  }
  .cajas02 img {
    width: 10em;
  }
  .cajas03 {
    position: absolute;
    bottom: -40%;
      left: -3%;
    z-index: 100;
  }
  .cajas03 img {
    width: 10em;
  }
  .cajas04 {
    position: absolute;
    top: -30%;
      right: 0%;
    z-index: 100;
  }
  .cajas04 img {
    width: 9em;
  }
}
@media (min-width: 992px) {
  .cajas04 {
    position: absolute;
    top: -40%;
      right: -4%;
    z-index: 100;
  }
  .cajas04 img {
    width: 11em;
  }
}
@media (min-width: 1200px) {
  .cajas02 {
      right: 15%;
  }
  .cajas02 img {
    width: 11em;
  }
  .cajas03 {
    position: absolute;
    bottom: -35%;
      left: 1%;
    z-index: 100;
  }
  .cajas04 {
    position: absolute;
    top: -40%;
      right: -4%;
    z-index: 100;
  }
  .cajas04 img {
    width: 13em;
  }
}
/* CAJAS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.cajas {
  background: url(../img/caja-boxmi-01.jpg) no-repeat;
  background-position: 10% 100%;
    background-size: auto 60%;
}
.cajas>.container>.row>div {
  padding-bottom: 30em;
}
.cajas h1 {
  color: #5E3DFC;
}
table {
  font-size: 0.7em;
  width: 100%;
}
table tr td span {
  color: #F74164;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
table tr th {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
table thead tr th {
  border-bottom: 1px solid #19083A;
  padding-bottom: 1em;
}
table tbody tr:nth-child(1) td {
  padding-top: 1em;
}
@media (min-width: 768px) {
  .cajas {
    background-position: 350% 60%;
    background-size: auto 120%;
    padding-top: 5rem;
  }
  .cajas>.container>.row>div {
    padding-bottom: 0em;
  }
}
@media (min-width: 992px) {
  .cajas {
    background-position: 150% 60%;
    background-size: auto 120%;
  }
}
@media (min-width: 1200px) {
  .cajas {
    background-position: 140% 60%;
      background-size: auto 135%;
  }
}
/* OBJETOS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.objetos {
  position: relative;
}
.objetos h1 {
  color: #5E3DFC;
}
.objetos p a {
  color: #F74164;
  text-decoration: underline;
  font-weight: 700;
}
@media (min-width: 768px) {
  .objetos {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  /**/
}
@media (min-width: 1200px) {
  /**/
}
/* DASHBOARD +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.dashboard {
  position: relative;
  background-color: #19083A;
  padding-bottom: 5rem;
}
.dashboard .container-fluid {
  padding-left: 0;
}
.dashboard h1 {
  color: #F74164;
}
.dashboard p {
  color: #FFF;
}
.dashboard-info-col {
  flex-direction: column;
}
.dashboard-info-col h1 {
  padding-left: 1em;
  padding-right: 1em;
}
.dashboard-info-col div {
  padding-left: 2.2em;
  padding-right: 2.2em;
}
.dashboard-access-btn,
.dashboard-access-btn:active,
.dashboard-access-btn:link,
.dashboard-access-btn:visited,
.dashboard-access-btn:focus {
  position: absolute;
  width: 15rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background-color: #F74164;
  color: #FFF;
  bottom: 0;
  left: 50%;
  font-family: 'Oswald', sans-serif;
  font-size: 0.9rem;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.dashboard-access-btn:hover {
  background-color: #D83E5C;
  text-decoration: none;
}
@media (min-width: 768px) {
  .dashboard {
    padding-bottom: 2rem;
  }
  .dashboard-info-col {
    flex-direction: row;
    align-items: center;
  }
  .dashboard-info-col h1 {
    padding-left: 0em;
    padding-right: 0em;
    width: 50%;
  }
  .dashboard-info-col div {
    padding-left: 0em;
    padding-right: 0em;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .dashboard-info-col h1 {
    padding-right: 2em;
  }
}
@media (min-width: 1200px) {
  .dashboard-info-col h1 {
    width: 35%;
    padding-right: 0.5em;
  }
}


footer {
  background-color: #5E3DFC;
  padding: 5vh 2vh 13vh 2vh;
  position: relative;
}
.footer-logo-container,
.footer-nav-container,
.footer-social-container {
  display: flex;
}
.footer-logo-container {
  flex-direction: column;
}
.footer-logo-container a,
.footer-logo-container p {
  text-align: center;
  color: #FFF;
  font-weight: 100;
  font-size: 0.7em;
}
.footer-logo-container img {
  height: 40px;
}
.footer-social-container {
  padding: 0 10vh;
}
.footer-social-container a {
  flex-grow: 1;
  text-align: center;
  padding: 4vh 0;
  font-size: 1.5em;
  color: #FFF;
}
.footer-nav-container {
  padding: 2vh 5vh 5vh 5vh;
}
.footer-nav-container nav {
  flex-grow: 1;
}
.footer-nav-container ul {
  list-style-type: none;
  padding: 0;
}
.footer-nav-container ul li a,
.footer-nav-container ul li a:link,
.footer-nav-container ul li a:active,
.footer-nav-container ul li a:visited {
  display: block;
  color: #FFF;
  text-decoration: none;
  padding: 1vh 0;
  font-size: 0.8em;
}

.admin-dashboard-menu {
  display: none;
  margin-top: 1rem;
}

.admin-dashboard-menu img{
  width: 15px !important;
}

.admin-dashboard-menu .dashboard-menu-item {
  padding: 0.5rem;
  border-radius: 5px;
}
@media (max-width: 576px) {
  .admin-dashboard-menu {
    display: block;
  }
}
@media (min-width: 768px) {
  footer {
    display: flex;
    padding: 3vh 5vh;
  }
  .footer-social-container {
    padding: 0 0vh;
    flex-grow: 1;
    order: 3;
  }
  .footer-nav-container {
    padding: 0 5vh;
    flex-grow: 3;
    order: 2;
  }
  .footer-logo-container {
    flex-grow: 1;
    order: 1;
    text-align: left;
  }
  .footer-social-container a {
    font-size: 0.8em;
  }
  .footer-nav-container ul li a,
  .footer-nav-container ul li a:link,
  .footer-nav-container ul li a:active,
  .footer-nav-container ul li a:visited {
    display: block;
    color: #FFF;
    text-decoration: none;
    padding: 0.4vh 0;
    font-size: 13px;
  }
  .footer-logo-container img {
    height: 30px;
  }
  .footer-logo-container a,
  .footer-logo-container p {
    text-align: left;
  }
}
@media (min-width: 992px) {
  footer {
    padding: 6vh 10vh;
  }
}
@media (min-width: 1200px) {
  footer {
    padding: 6vh 15vh;
  }
}

.center-row-ol {
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .center-row-ol ol {
    padding-left: 0;
  }

  .order-grid {
    grid-template-columns: 1fr;
    margin: 1rem;
  }
}
