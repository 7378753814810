.session-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4em;
  padding-bottom: 4em;
  flex: 1 1 50%;
}

.bigger-input {
  width: 50vw;
}

.session-title {
  font-size: 1.5em;
}

.sign-in-btn {
  border-radius: 0;
  background-color: #FFF;
  padding: 2vh 3vh;
  margin-left: 1.5em;
  margin-right: 1.5em;
  color: #19083A;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  cursor: pointer;
  border: 2px solid #19083A;
  -webkit-box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  -moz-box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
}
.sign-in-btn:hover {
  box-shadow: 1rem 1rem 0px 0.2em rgba(25,8,58,1);
}

.obt-legal {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-around;
}
.obt-legal label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  font-size: 0.7em;
}
.obt-legal label span {
  font-weight: 700;
  color: #F74164;
  font-family: 'Oswald', sans-serif;
}

.obt-legal-sign-in {
  flex-direction: column;
  align-items: center;
}

.form-group-sign-in {
  display: flex;
}
.form-group-sign-in input {
  flex: 1 1 100%;
  border: 2px solid #19083A;
  padding: 1em;
  border-radius: 1vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin-bottom: 1em;
}

@media only screen and (max-width: 767px) {
  .session-title {
    margin-left: 1em;
    margin-right: 1em;
  }
  .bigger-input {
    width: 80vw;
    margin-bottom: 1em;
  }
}
