.notification-error {
  background-color: #F74164 !important;
}

.notification-info {
  background-color: #5E3DFC !important;
}

.notification-message {
  font-size: 0.8em;
}
