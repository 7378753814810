input[type=range] {
  height: 35px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #5E30FF;
  height: 20px;
  width: 20px;
  border-radius: 32px;
  background: #5E30FF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #FFFFFF;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #5E30FF;
  height: 20px;
  width: 20px;
  border-radius: 32px;
  background: #5E30FF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #FFFFFF;
  border: 1px solid #010101;
  border-radius: 20px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #FFFFFF;
  border: 1px solid #010101;
  border-radius: 20px;
  box-shadow: 1px 1px 1px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000031;
  border: 1px solid #5E30FF;
  height: 20px;
  width: 20px;
  border-radius: 32px;
  background: #5E30FF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #FFFFFF;
}
input[type=range]:focus::-ms-fill-upper {
  background: #FFFFFF;
}


.notSelectable {
  opacity: 0.2;
  user-select: none;
  pointer-events: none;
}