html, body {
  font-family: 'Lato', sans-serif;
}
footer {
  background-color: #5E3DFC;
}
sup { color: #F74164 }
.footer-logo-container {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  order: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.footer-nav-container,
.footer-social-container {
  display: flex;
  flex-direction: row;
}
.footer-nav-list {
  display: flex;
  flex-direction: column;
}
.footer-logo-container p {
  text-align: left;
  color: #FFF;
  font-weight: 100;
  font-size: 1.4em;
}
.footer-social-container a {
  flex-grow: 1;
  text-align: center;
  padding: 4vh 0;
  font-size: 16px;
  color: #FFF;
}
.footer-nav-container {
  padding: 2vh 5vh 5vh 5vh;
}
.footer-nav-container nav {
  flex-grow: 1;
}
.footer-nav-container ul {
  list-style-type: none;
  padding: 0;
}
.footer-nav-container a,
.footer-nav-container a:link,
.footer-nav-container a:active,
.footer-nav-container a:visited {
  display: block;
  color: #FFF;
  text-decoration: none;
  padding: 1vh 0;
  font-size: 1.3em;
}
/* MAIN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.style-pointer {
  cursor: pointer;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding-top: 5em;
}
.aside-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
}
.main-container-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.main-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
}

@media screen and (min-width: 768px) {
  .main-section {
    padding: 0 3rem;
  }
}

.main-confirm {
  flex: 0 0 20% !important;
}
.aside-confirm,
.aside-signup {
  flex: 0 0 80% !important;
}

.checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: 'Oswald', sans-serif;
}
.checkout-box {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  flex: 1 1 25%;
}

.checkout-box hr {
  width: -webkit-fill-available;
  border-color: #19083A;
  flex-grow: 0;
  opacity: 0.5;
  border-width: 0.5px;
}

.wallet {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.wallet h2 {
  font-size: 1.5rem;
}

.wallet .card {
  border-radius: 20px;
  min-height: 100px;
  padding: 1rem;
  box-shadow: 0.8em 0.8em 0px 0.2em rgba(25,8,58,1);
  cursor: pointer;
}

.wallet .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  
}
@media screen and (max-width: 1380px) {
  .wallet .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .wallet .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wallet .newCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet .checkMark {
  position: absolute;
  top: 0px;
  right: 1rem;
  color: #F74164;
  font-size: 60px;

}

.newCard .plus {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgb(25,8,58);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 1rem;
}

/* FAQ ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.main-container-faq {
  display: flex;
  padding: 4vh 0;
  flex-direction: column;
  flex-flow: column wrap;
  justify-content: flex-end;
  cursor: pointer;
  align-items: flex-end;
  margin: 0 0 0 2%;
}
.faq-container {
  display: flex;
  background-color: #19083A;
  width: 75%;
  padding: 4vh 0 4vh 0;
  border-top-left-radius: 8vh;
  border-bottom-left-radius: 8vh;
}
.faq-ico-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  margin-left: 1em;
}
.faq-ico-container img {
  height: 4em;
}
.faq-info-container {
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2em;
}
.faq-info-container h4 {
  color: #C0FFDF;
  font-family: 'Oswald', sans-serif;
  margin: 0;
  align-self: flex-start;
}
.faq-info-container p {
  color: #FFF;
  margin: 0;
  font-size: 1.5em;
  font-weight: 300;
  align-self: flex-start;
}
.faq-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-right: 1em;
}
.faq-questions-container {
  display: flex;
  width: 95%;
  padding: 4vh 2vh 4vh 4vh;
}
.faq-info-container p {
  font-size: 1em;
}
.faq-questions-container ol {
  padding: 0;
  column-count: 2;
  column-gap: 20px;
}
.faq-questions-container ol li {
  font-family: 'Oswald', sans-serif;
  color: #5E3DFC;
}
.faq-questions-container ul {
  list-style-type: none;
  padding: 0;
}
.faq-questions-container ul li {
  margin-bottom: 3vh;
  font-family: 'Lato', sans-serif;
  font-size: 0.6em;
  color: #000;
}
/* ORDER ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.order-bar {
  position: fixed;
  width: 94%;
  background-color: #19083A;
  bottom: 0;
  top: 4em;
  left: 3%;
  -webkit-transform: translateY(calc(100% - 5em));
  -moz-transform: translateY(calc(100% - 5em));
  -ms-transform: translateY(calc(100% - 5em));
  -o-transform: translateY(calc(100% - 5em));
  transform: translateY(calc(100% - 5em));
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 90;
  -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.aside-signup .order-bar,
.aside-confirm .order-bar {
  position: fixed;
  width: 94%;
  background-color: #19083A;
  bottom: 0;
  top: 100px;
  left: 3%;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* z-index: 90; */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.order-bar::after {
  content: '';
  display: none;
  position: absolute;
  width: 60%;
  min-height: 80vh;
  background: url(./img/order-box-01.svg) no-repeat;
  background-size: 120% auto;
  background-position: -10vh 0;
  left: 0;
  bottom: -60vh;
  margin-bottom: 2em;
}
.aside-confirm .order-bar::after,
.aside-signup .order-bar::after {
  content: '';
  display: none;
  position: absolute;
  width: 50vh;
  height: 50vh;
  background: url(./img/order-box-01.svg) no-repeat;
  background-size: 100% auto;
  background-position: -10vh 0;
  left: 0;
  bottom: -30vh;
}
.ob-toggle {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.order-bar-header {
  display: flex;
  flex-direction: row;
}
.order-bar-content {
  display: flex;
  flex-grow: 10;
  flex-direction: column;
  padding: 2vh 2vw;
}
.aside-confirm .order-bar-content,
.aside-signup .order-bar-content {
  flex-direction: column;
}
.aside-confirm>.order-bar>.order-bar-content>.order-bar-col:nth-child(2) {
  justify-content: flex-end;
}
.aside-signup>.order-bar>.order-bar-content>.order-bar-col:nth-child(1) {
  align-items: flex-end;
}
.obh-ico {
  margin-right: 1vw;
}
.obh-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3em;
  padding-top: 2em;
}
.obh-arrow {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.obh-coupon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #C0FFDF;
  padding: 1em;
  border-bottom-left-radius: 3vh;
  border-top-right-radius: 3vh;
  cursor: pointer;
  align-self: flex-end;
  justify-self: flex-end;
}
.obh-coupon p {
  display: block;
  font-family: 'Oswald', sans-serif;
  color: #19083A;
  text-transform: uppercase;
  margin: 0 0 0 1vw;
  padding: 0;
  font-size: 1em;
}
.obh-title h2 {
  font-family: 'Oswald', sans-serif;
  color: #C0FFDF;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-weight: 350;
  font-size: 1.5em;
  padding-right: 1em;
}
.obh-coupon img {
  height: 40px;
}
.obh-arrow img {
  height: 10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.ob-toggle .obh-arrow img {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.order-bar-total {
  display: flex;
  justify-content: space-around;
  flex-grow: 4;
  margin-bottom: 13vh;
}
.aside-confirm .order-bar-total {
  display: flex;
  justify-content: inherit;
  flex-grow: 0;
  margin-bottom: 0vh;
}

.obt-total {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.obt-total h5 {
  color: #C0FFDF;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  margin: 0;
  text-align: right;
  font-weight: 300;
}
.obt-labels {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.obt-labels p {
  margin: 0 0 1vh 0;
  padding-right: 1em;
  text-align: right;
  color: #F74164;
  font-weight: 400;
}
.obt-labels .discount {
  background-color: #F74164;
  color: #FFF;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top-left-radius: 1vh;
  border-bottom-left-radius: 1vh;
}
.obt-price .discount {
  background-color: #F74164;
  color: #FFF;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.obt-price .discount::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2vh;
  width: 20px;
  background: url(./img/coupon-icon-price.svg) no-repeat;
  background-size: 50%;
  background-position: center;
}
.obt-price {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'Oswald', sans-serif;
}
.obt-price p {
  color: #C0FFDF;
  margin: 0 0 1vh 0;
}
.obt-price h5 {
  color: #C0FFDF;
  margin: 0 0 1vh 0;
  font-size: 1.5em;
  font-weight: 300;
}
.obt-price h5::before {
  content: '';
  display: block;
  width: 80%;
  height: 1px;
  background-color: #C0FFDF;
  margin-bottom: 10px;
}
.order-item {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  margin-bottom: 1em;
  position: relative;
}
.order-item span {
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  font-family: 'Oswald', sans-serif;
  color: #F74164;
  background-color: #19083A;
  padding: 5px 8px;
  border: 2px solid #C0FFDF;
  border-radius: 30px;
}
.order-item p,
.order-item h4 {
  font-family: 'Oswald', sans-serif;
  color: #C0FFDF;
  margin: 0;
  font-size: 1.2em;
  font-weight: 300;
}
.order-item p small,
.order-item h4 small,
.order-item h5 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #C0FFDF;
  display: block;
}
.order-item h4 small {
  color: #F74164;
}
.order-item h5:not(:last-child) {
  margin: 0 0 10px 0;
}
.order-item h5:last-child {
  margin: 0;
}
.oi-col-1 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.oi-col-2 {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
}
.oi-col-2 p {
  font-size: 1.5em;
}
.order-data {
  display: flex;
  justify-content: space-around;
  position: relative;
  color: #FFF;
  font-family: 'Oswald', sans-serif;
  font-size: 9px;
  margin-bottom: 10px;
}
.order-data p {
  margin: 0;
  font-size: 1.5em;
}
.od-location,
.od-date {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.od-location i,
.od-date i {
  margin-right: 5px;
}
.confirm-cc-icon {
  fill: #C0FFDF;
}
.obtp-credit-card {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items:flex-start;
  align-content:flex-start;
}
.obtp-credit-card * {
  display: flex;
}
.obtp-credit-card p {
  padding-left: 1vh;
}
.obt-confirm-b {
  display: flex;
  position: relative;
  justify-content: center;
}
.obt-confirm-b button {
  border-radius: 0;
  background-color: #19083A;
  border: 3px solid #FFF;
  color: #FFF;
  padding: 2vh 3vh;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  min-width: 30vh;
}
.obt-confirm-b button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  left: 1.5vh;
  top: 1.5vh;
  z-index: -1;
}
.order-resume-title {
  display: flex;
}
.order-resume-title * {
  display: flex;
  color: #FFF;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 450;
}
.back-btn,
.back-btn:link,
.back-btn:active,
.back-btn:visited {
  color: #F74164;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 1.5em;
  cursor: pointer;
}
.obc-submenu {
  display: flex;
  justify-content: flex-start;
  padding: 2vh 3vh;
}
.obc-submenu img {
  height: 6vh;
}
.order-bar-col>.obc-submenu>a>img:nth-child(1) {
  margin-right: 2vh;
}
.obc-form {
  display: flex;
  justify-content: flex-start;
  padding: 2vh 3vh;
  flex-direction: column;
}
.obc-form * {
  display: flex;
}
.obc-form .form-group {
  flex-wrap: wrap;
  margin-bottom: 1vh;
}
/* NUMBER NAV ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.number-nav {
  position: relative;
  display: flex;
  background-color: #19083A;
  justify-content: center;
  padding-top: 2vh;
}
.number-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0;
}
.number-nav ul li {
  display: inline-block;
  color: #FFF;
  font-family: 'Oswald', sans-serif;
  font-size: 2.5em;
  vertical-align: text-top;
  cursor: pointer;
}
.number-nav ul li:not(:last-child) {
  margin-right: 3vw;
}
.number-nav ul li h5,
.number-nav ul li p {
  margin: 0;
}
.number-nav ul li p {
  display: block;
  font-size: 0.4em;
  text-transform: uppercase;
}

@media screen and (max-width: 1380px) {
  .number-nav ul li p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1030px) {
  .number-nav ul li p {
    font-size: 12px;
  }
}


.number-nav .active {
  color: #F74164;
}
.img-btn-desktop {
  display: none;
}
.section-step-title {
  position: relative;
  display: flex;
  background-color: inherit;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 0;
  padding: 0vh 1vw 0vh 0vh;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.sst-nav-l,
.sst-nav-r,
.sst-title {
  display: flex;
  cursor: pointer;
}
.sst-nav-l{
  margin-right: 1rem;
}
.sst-nav-r {
  margin-left: 1rem;
}
.disabled {
  opacity: 0.3;
}
.sst-title {
  justify-content: flex-start;
  flex-grow: 1;
}
.sst-title h2 {
  color: #FFF;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 0.5em;
}
.number-nav {
  background-color: inherit;
  padding-top: 0;
  justify-content: space-between;
}
.number-nav ul li {
  color: #E2DADC;
}
.number-nav ul li:not(:last-child) {
  margin-right: 3vw;
}

@media screen and (max-width: 1380px) {
  .number-nav ul li:not(:last-child) {
    margin-right: 2vw;
  }
}

@media screen and (max-width: 1030px) {
  .number-nav ul li:not(:last-child) {
    margin-right: 1vw;
  }
}

.number-nav ul .active p {
  color: #19083A;
}
.img-btn-desktop {
  display: block;
  height: 4em;
}
.sst-title h2 {
  color: #19083A;
}
/*  SUBMENU */
.step1-section {
  display: flex;
  flex-direction: row;
}
.step1-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.s1-submenu {
  display: flex;
  padding: 0 0 2vh 0;
}
.s1-submenu ul {
  display: flex;
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.s1-submenu ul li {
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-top: 2px solid #C3B7BA;
  cursor: pointer;
}
.s1-submenu ul .active {
  border-top: 2px solid #F74164;
}
.s1-submenu ul li p {
  color: #C3B7BA;
  margin: 0;
}
.active-text {
  color: #f74164 !important;
}
.inactive-text {
  color: #C3B7BA !important;
}
.s1-submenu ul li img {
  height: 70%;
}
@media (min-width: 992px) {
  .s1-submenu ul li:first-child {
    margin-right: 1vh;
  }
}

/* SUBMENU */

.quoting-section {
  display: flex;
  padding: 4vh 0;
  flex: 1;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
}
.quoting {
  display:flex;
  flex-direction: row;
}
.box-column {
  width: 300px ;
  height: 300px ;
  border-radius: 2vh ;
  border:  2px solid #F0F0F0 ;
  display: flex;
  flex: 1;
  background-color: #FFF;
  flex-direction: column;
}

/* BOXES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.s1-boxes {
  display: flex;
  flex-direction: row;
}
.s1-boxes .s1b-item:not(:last-child) {
  margin-right: 2vh;
}
.s1-boxes * {
  display: flex;
}
.s1b-item {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  margin-bottom: 2em;
  border-radius: 2vh;
  flex-direction: column;
  flex: 1 1 100%;
}
.s1bi-row {
  flex-grow: 1;
  flex-direction: row;
}
.s1bi-row div {
  flex-grow: 1;
}
.s1bi-size {
  padding-left: 2vh;
}
.s1bi-size p {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 70px;
  font-weight: 700;
  color: #19083A;
}
.s1bi-size p span {
  font-weight: 400;
  font-size: 0.2em;
  text-transform: uppercase;
  align-items: flex-start;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0% 0% 10px;
  transform-origin: 0% 0% 10px;
  margin-left: 1.5em;
  margin-top: 2em;
}
.s1bi-dimensions {
  justify-content: flex-end;
  flex-direction: column;
  padding-right: 2vh;
  align-self: flex-start;
  padding-top: 1em;
}
.s1bi-number {
  cursor: default;
  margin: 0;
  align-self: center;
  justify-content: center;
}
.s1bi-dimensions img {
  align-self: flex-end;
}
.s1bi-dimensions ul {
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  align-items: flex-end;
}
.s1bi-dimensions ul li {
  color: #C3B7BA;
}
.s1bi-dimensions ul li * {
  flex-direction: column;
}
.s1bi-dimensions ul li p {
  flex-direction: row;
  margin: 0;
}
.s1bi-dimensions ul li p strong {
  margin-left: 0.7vh;
}
.s1bi-price {
  padding-left: 2vh;
  flex-direction: column;
}
.s1bi-price h3,
.s1bi-price h5 {
  margin: 0;
  padding: 0;
}
.s1bi-price h3 {
  font-size: 2em;
  font-weight: 300;
}
.s1bi-price h5 {
  text-transform: uppercase;
  color: #F74164;
  font-size: 1em;
}
.s1bi-price {
  flex-grow: 1;
}
.s1bi-photo img {
  max-width: 75%;
  width: 100%;
  height: 100%;
  display: block;
}
.s1bi-box-selector {
  justify-content: center;
}
.s1bi-box-selector h4 {
  font-size: 2em;
  font-weight: 400;
  padding: 1em;
  width: 120px;
  text-align: center;
}
.s1bi-add-box-button,
.s1bi-remove-box-button {
  justify-content: center;
  position: relative;
}
.s1bi-add-box-button img,
.s1bi-remove-box-button img{
  width: 90px;
  position: absolute;
  left: -webkit-calc(50% - 45px);
  left: -moz-calc(50% - 45px);
  left: -ms-calc(50% - 45px);
  left: -o-calc(50% - 45px);
  left: calc(50% - 45px);
  bottom: -webkit-calc(50% - 55px);
  bottom: -moz-calc(50% - 55px);
  bottom: -ms-calc(50% - 55px);
  bottom: -o-calc(50% - 55px);
  bottom: calc(50% - 55px);
}
.s1bi-rb-title {
  background-color: #F74164;
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: -webkit-calc(-70px - 2vh);
  bottom: -moz-calc(-70px - 2vh);
  bottom: -ms-calc(-70px - 2vh);
  bottom: -o-calc(-70px - 2vh);
  bottom: calc(-70px - 2vh);
  height: 75px;
  justify-content: center;
  align-items: flex-end;
  z-index: -2;
  padding-bottom: 1vh;
  border-bottom-left-radius: 2vh;
  border-bottom-right-radius: 2vh;
}
.s1bi-rb-shadow {
  background-color: #F74164;
  position: absolute;
  width: 90px;
  height: 90px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  -ms-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  -o-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
  bottom: -55px;
  border-radius: 45px;
  z-index: -1;
}
.s1bi-rb-title p {
  margin: 0;
  color: #FFF;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
}
.custom-product {
  justify-content: center;
  background-color: #19083A;
  color: white;
  padding: 1em;
  border-radius: 2vh;
}
.contact-subtitle {
  padding-bottom: 1em;
}
@media (min-width: 768px) {
  .s1bi-add-box-button img,
  .s1bi-remove-box-button img {
    width: 70px;
    bottom: -35px;
    left: -webkit-calc(50% - 35px);
    left: -moz-calc(50% - 35px);
    left: -ms-calc(50% - 35px);
    left: -o-calc(50% - 35px);
    left: calc(50% - 35px);
  }
  .s1bi-rb-shadow {
    width: 70px;
    height: 70px;
    bottom: -35px;
  }
  .s1bi-rb-title {
    bottom: -webkit-calc(-60px - 2vh);
    bottom: -moz-calc(-60px - 2vh);
    bottom: -ms-calc(-60px - 2vh);
    bottom: -o-calc(-60px - 2vh);
    bottom: calc(-60px - 2vh);
    height: 65px;
  }
}
/* QUESTIONS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.questions * {
  display: flex;
}
.questions {
  display: flex;
  flex-wrap: wrap;
}
.question-item * {
  margin: 0;
}
.question-item h3 {
  color: #F74164;
  text-transform: uppercase;
  margin-bottom: 1vh;
  font-size: 1em;
}
.q-switch {
  position: absolute;
  bottom: -3em;
  align-self: center;
}
.q-switch img {
  width: 150px
}


.floating-item {
  position: fixed;
  right: 15px;
  bottom: 20px;
  color: #25e47b;
  font-size: 60px;
  z-index: 90;
}
/* FORMS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.shipping,
.payment {
  flex-direction: row;
}
.shipping *,
.payment * {
  display: flex;
  font-family: 'Oswald', sans-serif;
}
.shipping-container .form-group {
  flex-grow: 1;
  margin-bottom: 2vh;
  flex-wrap: wrap;
}

.shipping-image {
  flex-grow: 1;
}

.form-group input,
.form-group select,
.form-group textarea {
  flex-grow: 1;
  border: 2px solid #19083A;
  padding: 1em;
  border-radius: 1vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.coupon {
  display: flex;
  justify-content: space-between;
}
.coupon-input {
  flex-grow: 1;
  border: 2px solid #19083A;
  padding: 1em;
  border-radius: 1vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-family: 'Oswald', sans-serif;
  margin-right: 1em;
}
.coupon-btn {
  flex-grow: 1;
  border: 2px solid #19083A;
  padding: 1em;
  border-radius: 1vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #19083A;
  color: white;
  font-family: 'Oswald', sans-serif;
}
.form-group .error {
  border: 2px solid #f74164 !important
}
.form-group-sign-in .error {
  border: 2px solid #f74164 !important
}
.form-group .input-lg {
  padding: 4vh 2vh;
  font-size: 16px;
}
.form-group .input-50 {
  flex-basis: 44%;
  margin-bottom: 2vh;
}
.form-group .input-50:last-child {
  margin-bottom: 0;
}
.form-group .input-33 {
  flex-basis: 96%;
  margin-bottom: 2vh;
}
.form-group .input-10 {
  flex-basis: 96%;
  margin-bottom: 2vh;
}
.form-zipcode-lock {
  border: 2px solid #19083A;
  padding: 1vh 2vw;
  border-radius: 2vh;
  align-items: center;
  flex: 0 0 auto;
  text-decoration: none;
  color: #19083A;
}
.form-zipcode-lock p {
  font-family: 'Oswald', sans-serif;
}
.form-zipcode-lock p span {
  margin-right: 0.5vh;
  align-self: center;
}
.form-zipcode-lock * {
  margin: 0;
}
.form-zipcode-lock img {
  height: 15px;
  margin-left: 1vh
}

/* CALENDAR ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.calendar * {
  display: flex;
}
.calendar-hour-selection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 100%;
}
.cal-day-items {
  flex-wrap: wrap;
  padding: 3vh 0;
}
.cal-week-days {
  flex-wrap: nowrap;
  padding-top: 3vh;
  justify-content: space-around;
}
.cal-week-days p {
  margin: 0;
  flex: 1 1 auto;
  justify-content: center;
}
.day-item {
  padding: 1%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -ms-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -o-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  border-radius: 1vh;
  flex-direction: column;
  justify-content: center;
  margin-right: 0;
  margin-bottom: 2%;
  flex: 0 1 12.5%;
  cursor: pointer;
}
.day-item .day-num {
  font-size: 3em;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  margin: 0;
  color: #C3B7BA;
  justify-content: center;
}
.day-item .month {
  margin: 0;
  text-transform: uppercase;
  color: #F74164;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  justify-content: flex-end;
}
.day-off {
  background-color: rgba(226,218,220,0.3);
  cursor: default;
}
.day-off .day-num,
.day-off .month {
  color: #E2DADC;
}
.day-sel {
  background-color: #5E3DFC;
  position: relative;
}

.day-sel .day-num {
  color: #C0FFDF;
}
.day-sel .month {
  color: #FFF;
}

.ReactModalPortal {
  z-index: 100;
}
.ReactCollapse--content {
  display: flex;
  justify-content: flex-end;
}

hr.someClass {
  width: 100%;
  border-color: #19083A;
}

.questions .question-item:nth-child(2n+0) {
  margin-left: 2vh;
}

.question-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 2vh;
  padding: 3vh 3vh 7vh 3vh;
  flex: 1 1 45%;
}
.common-container {
  display: flex;
  flex-direction: column;
  min-height: 45vh;
  margin-left: 5em;
  margin-right: 5em;
  padding-top: 4em;
  margin-bottom: 2em;
}
.common-container h2 {
  color: #5E3DFC;
}
.common-container h4 {
  color: #5E3DFC;
}
.common-container h3 {
  color: #F74164;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}



/*  MEDIA QUERIES */
@media only screen and (min-width: 768px) {
  footer {
    display: flex;
    padding: 3vh 5vh;
  }
  .footer-social-container {
    padding: 0 0vh;
    flex-grow: 1;
    order: 3;
  }
  .footer-nav-container {
    padding: 0 5vh;
    flex-grow: 3;
    order: 2;
  }
  .order-bar {
    min-width: 30vw;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    bottom: inherit;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3vh;
    min-height: 120vh;
    overflow-y: hidden;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  .notification {
    font-size: 1.5em !important;
  }

  .img-btn-mobile {
    display: none;
  }
  .aside-confirm .order-bar,
  .aside-signup .order-bar {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3vh;
    min-height: 90vh;
    justify-content: flex-start;
    left: 0;
    top: 0;
  }
  .order-bar::after,
  .aside-confirm .order-bar::after,
  .aside-signup .order-bar::after {
    display: block;
  }
  .obh-arrow {
    display: none;
  }
  .order-item {
    padding: 0 1em;
    margin-left: 2vh;
    margin-bottom: 10px;
  }
  .order-item span {
    position: absolute;
    top: -webkit-calc(50% - 10px);
    top: -moz-calc(50% - 10px);
    top: -ms-calc(50% - 10px);
    top: -o-calc(50% - 10px);
    top: calc(50% - 10px);
    left: -25px;
    background-color: inherit;
    padding: 0;
    border: 0;
    font-size: 1.5em;
  }
  .aside-confirm .order-bar {
    background-image: url(./img/confirm-background.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 90%;
  }
  .aside-signup .order-bar {
    background-image: url(./img/confirm-background.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 120%;
  }
  .aside-confirm .order-bar-content,
  .aside-signup .order-bar-content {
    flex-direction: row;
  }
  .order-bar-col {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
  }
  .aside-signup>.order-bar>.order-bar-content>.order-bar-col:nth-child(2) {
    flex-basis: 70%;
    justify-content: space-between;
  }
  .aside-confirm .obt-total h5 {
    color: #19083A;
  }
  .aside-confirm .obt-price p {
    color: #19083A;
  }
  .aside-confirm .obt-price h5 {
    color: #19083A;
    font-weight: 400;
    font-size: 25px;
  }
  .aside-confirm .obt-price .discount {
    color: #FFF;
  }
  .aside-confirm .order-data {
    display: flex;
    justify-content: space-around;
    position: relative;
    color: #19083A;
    font-family: 'Oswald', sans-serif;
    font-size: 1em;
    margin-bottom: 3vh;
    padding-left: 7vw;
  }
  .aside-confirm .order-bar-total {
    padding-left: 3vh;
  }
  .confirm-cc-icon {
    fill: #19083A;
  }
  .obt-confirm-b {
    padding-left: 16vh;
  }
  .obt-confirm-b button {
    position: absolute;
    bottom: -5vh;
    border-radius: 0;
    background-color: #FFF;
    border: 3px solid #19083A;
    padding: 2vh 3vh;
    color: #19083A;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 15px;
    cursor: pointer;
  }
  .obt-confirm-b button::after {
    background-color: #19083A;
  }
  .order-resume-title {
    padding-left: 19vh;
  }
  .order-resume-title * {
    color: #19083A;
  }
  .obc-submenu {
    display: flex;
    justify-content: flex-start;
    padding: 0vh 0vh 0vh 23vh;
  }
  .obc-signin-form {
    flex-basis: 40vh;
  }
  .obc-signin-form .form-group {
    position: relative;
  }
  .obc-signin-form .form-group::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -40px;
  }
  .obc-signin-form>.form-group:nth-child(1)::after {
    background: url(./img/signin-user-icon.svg) no-repeat;
    background-size: auto 60%;
    background-position: center;
  }
  .obc-signin-form>.form-group:nth-child(2)::after {
    background: url(./img/signin-password-icon.svg) no-repeat;
    background-size: auto 60%;
    background-position: center;
  }
  .sst-nav-mobile {
    display: none;
  }

  .calendar>.cal-day-items>.day-item:not(:nth-child(7n+7)) {
    margin-right: 2%;
  }

  .sst-title-mobile{
    display: none;
  }

  .number-nav-checkout {
    margin: 0 0 2vh 4vw;
  }

  .payment-image {
    margin-right: 2em;
  }

  .form-group select {
    cursor: pointer;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1.3em),
      calc(100% - 15px) calc(1.3em),
      calc(100% - 3.5em) 1em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }
  .sign-in-container-mobile {
    display: none;
  }
  .sign-in-container {
    display: flex;
    flex-direction: column;
    margin: 3em;
    min-height: 90vh;
  }
  .sign-in-column {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    margin-right: 1.5em;
  }
  .back-btn {
    padding-left: 0;
  }
  .form-group-sign-in {
    display: flex;
  }
  .form-group-sign-in input {
    flex: 1 1 100%;
    border: 2px solid #19083A;
    padding: 1em;
    border-radius: 1vh;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 767px) {
  /* phones */
  header .logo-wrapper {
    flex: 1 1 100%;
    margin-right: 0;
    min-height: 5em;
  }
  header nav {
    flex: 0;
  }
  .footer-logo-container {
    align-items: center;
  }
  header .main-logo {
    padding: 0 0 0 5vw;
  }
  .faq-questions-container ol {
    padding: 0;
    column-count: 1;
  }

  .obh-coupon p {
    display: none;
  }

  .floating-item {
    bottom: 0em;
    font-size: 60px;
    right: 5vw;
  }

  .img-btn-mobile {
    display: block;
  }
  .number-nav {
    position: fixed;
    display: flex;
    background-color: #19083A;
    justify-content: center;
    padding-top: 2vh;
    z-index: 2;
    width: 100%;
  }
  .number-nav ul li p {
    display: none;
  }
  .number-nav ul li:not(:last-child) {
    margin-right: 6vw;
  }
  .main-container {
    margin-top: 0;
    padding-top: 3.5em;
  }
  .img-btn-desktop {
    display: none;
  }
  .sst-nav-l,
  .sst-nav-r {
    padding: 0;
    margin-bottom: 0;
  }
  .sst-nav-mobile {
    padding: 2vh 2vw;
  }
  .section-step-title {
    position: fixed;
    background-color: #19083A;
    padding: 0 0;
    margin-top: -0.2em;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
    z-index: 1;
    width: 100%;
  }
  .sst-title {
    justify-content: center;
  }
  .sst-title h2 {
    color: #FFF;
    padding: 0;
    font-size: 1em;
    font-family: 'Oswald', sans-serif;
    text-align: left;
    text-transform: uppercase;
  }
  .s1-boxes {
    flex-wrap: wrap;
  }
  .s1b-item {
    margin: 0 2vw 2em 2vw;
  }
  .faq-container {
    width: 95%;
  }
  .order-bar-total {
    margin-bottom: 0;
  }
  .obh-ico {
    margin-right: 5vw;
  }
  .questions {
    display: block;
    padding-top: 10em;
  }
  .question-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 4vw 10vh 4vw;
    -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 2vh;
    padding: 3vh 3vh 7vh 3vh;
    max-width: 100%;
  }

  .shipping-image,
  .payment-image {
    display: none;
  }
  .calendar >.cal-day-items >.day-item {
    margin-right: 2vw;
  }
  .day-item {
    padding: 2%;
    flex: 1 1 12%;
  }
  .cal-week-days p {
    max-width: 10vw;
  }

  hr.someClass {
    display: none;
  }

  .aside-section {
    flex: 0;
  }

  .s1-submenu {
    padding-top: 10em;
  }

  .s1-submenu ul li p {
    font-size: 1.5em;
  }

  .shipping, .payment {
    /* padding: 1em; */
    padding-top: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .calendar {
    padding-top: 1em;
  }

  .calendar-hour-selection {
    flex-wrap: wrap;
  }

  .phone-margin-top {
    margin-top: 1em;
  }
  .day-item .day-num {
    font-size: 1.5em;
  }
  .checkout-container {
    padding-top: 8em;
  }
  .checkout-container div {
    margin-left: 0;
    margin-right: 1em;
  }
  .sign-in-container-mobile {
    display: flex;
  }
  .sign-in-container {
    display: none;
  }
  .hide-bar {
    display: none;
  }
  .common-container {
    margin-left: 2em;
    margin-right: 2em;
  }
  .contact-subtitle {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* portrait phones */
}

@media only screen and (min-width: 992px) {
  .faq-container {
    width: 65%;
  }
  .faq-questions-container {
    width: 60%;
  }
}
.pointer {
  cursor: pointer;
}

.rainbow-box {
  color: white;
  border-radius: 10px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  background-color: #5e3dfc;
}


@media only screen and (max-width: 767px) {
  .rainbow-box {
    grid-template-columns: repeat(2, 1fr);

  }
}

.quote-card {
  background-color: #f7f5f6;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.quote-selector {
  display: flex;
  align-items: center;
}
.quote-selector .prev-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #5E3DFC;
  color: white;
}
.quote-selector .value-area {
  font-size: 50px;
  font-weight: 900;
  margin: 0 1rem;
  line-height: 52px;
}
.quote-selector .next-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #5E3DFC;
  color: white;
}

.price {
  font-size: 20px;
  font-weight: 500;
  color: #f74164;
}

.insurance-card {
  background-color: #f7f5f6;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;

}

.insurance-small-text {
  font-size: 12px;
  margin-top: 0.5rem;
}

.showcase-card {
  border: 2px solid #19063b;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}